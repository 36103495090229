const $ = jQuery;
const $themePath = 'https://sundownadventureland.co.uk/public/wp-content/themes/sundown-adventure-land/';

// Import additional libraries here
import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import '../../node_modules/owl.carousel/dist/owl.carousel.min.js';
import '../../node_modules/webticker/jquery.webticker.min';
import '../../node_modules/lazysizes/lazysizes';
import '../../node_modules/jquery-ui/ui/widget.js';
import '../../node_modules/jquery-ui/ui/keycode.js';
import '../../node_modules/jquery-ui/ui/unique-id.js';
import '../../node_modules/jquery-ui/ui/widgets/accordion.js';
//import './popup';

let isMobile = window.matchMedia("only screen and (max-width: 991px)").matches;

$(window).on('resize', function() {
    isMobile = window.matchMedia("only screen and (max-width: 991px)").matches;
});


// Dynamic URL Social Media Share
// For partials/partial-social.php
$(document).ready(function(){
    let $socialUrl = window.location.href;
    let $facebook = 'http://www.facebook.com/sharer/sharer.php?u='+$socialUrl;
    let $twitter = 'http://twitter.com/intent/tweet?text='+$socialUrl;
    let $google = 'http://plus.google.com/share?url='+$socialUrl;
    let $pinterest = 'http://pinterest.com/pin/create/button/?url='+$socialUrl+'&media='+$('meta[property="og:image"]').attr("content");


    $('.social-share-columns .facebook a').attr('href', $facebook);
    $('.social-share-columns .twitter a').attr('href', $twitter);
    $('.social-share-columns .google a').attr('href', $google);
    $('.social-share-columns .pinterest a').attr('href', $pinterest);

    $('.follow .facebook a').attr('href', $facebook);
    $('.follow .twitter a').attr('href', $twitter);
    $('.follow .google a').attr('href', $google);
    $('.follow .pinterest a').attr('href', $pinterest);

    $('.navClick').click(function(e){
        $(this).toggleClass('open');
        $('header nav').toggleClass('open');
    });

    $('header nav li.parent > a').click(function(e) {
        if ( isMobile ) {
            e.preventDefault();

            $(this).parents('li.parent').find('ul.sub-menu').toggleClass('open');
        }
    });

    $('.tab-nav').click(function(){
        let id = $(this).data('tab');

        $('.tab-content').find('.content').removeClass('active');
        $('#' + id).addClass('active');

        if ( isMobile ) {
            $('html, body').animate({
                scrollTop : ( $('#' + id).offset().top - 25 ) + "px"
            }, 500);
        }
    });

});

// Rides Shield Carousel

$('#owl-carousel').owlCarousel({
    dots:false,
    lazyLoad: true,
    loop:true,
    margin:10,
    nav:true,
    navText: ["",""],
    responsive:{
        0:{
            items:2
        },
        768:{
            items:4
        },
        1000:{
            items:4
        }
    }
});

// Events Single Image Slider

$('#owl-carousel2').owlCarousel({
    autoplay: true,
    dots:false,
    lazyLoad: true,
    loop:true,
    margin:10,
    nav:true,
    navText: ["<img src='"+$themePath+"dist/images/left-arrow.png' alt='scroll left' />","<img src='"+$themePath+"dist/images/right-arrow.png' alt='scroll right' />"],
    items: 1
});

$(document).ready(function () {
    $('#webTicker').webTicker({
        speed: 150,
        height: '50px'
    });
});

$(document).ready(function () {
    $('.animated').hover(
        function (){
            $(this).removeClass('animated')
        }
    );
});

$( function() {
    $( "#accordion" ).accordion({
        heightStyle: "content",
        active: false,
        collapsible: true,
    });
} );

$(document).ready(function () {
   $('.news-link').hover(function () {
      $(this).next('div').toggleClass('open');
   });
});

document.addEventListener( 'wpcf7mailsent', function( event ) {
    location = 'https://sundownadventureland.co.uk/thank-you/';
}, false );

$(document).ready(function(){
    $('.christmas-button').click(function(){
        $('.popup').toggleClass('popup--active');
        $('.popup').css('display', 'block');
        $('html').css('overflow', 'hidden');
    })

    $('.popup-close').click(function(){
        $('.popup').toggleClass('popup--active');
        $('.popup').css('display', 'none');
        $('html').css('overflow', 'auto');
    })
})
